// modernizr // webp
import {modernizer}  from "./common/modernizr.js";
modernizer();
// load imageSwitch
import {imageSwitch} from "./common/sub_imageswtch.js";
imageSwitch();
// hamburger.
import{hamburgerImageCtrl} from "./common/hamburger-content-ctrl.js"

// load sass
import '../sass/common.scss'; //scssの読み込み
// console.log(location.pathname);


let renderSync = false;
let commonWindowHeight = (window.innerHeight / 1.75);
const header = document.getElementById('header');


function scrollEve() {
  if (!renderSync) {
    requestAnimationFrame(function () {
      renderSync = false;
      let CommonScrollTop = document.scrollingElement.scrollTop;
      let offsetYcontFooter = footer.getBoundingClientRect().top - commonWindowHeight;

      //header scroll change color.
      if (commonWindowHeight <= CommonScrollTop) {
        if (!header.classList.contains('active')) {
          header.classList.add('active');
        }
      }
      if (commonWindowHeight >= CommonScrollTop) {
        header.classList.remove('active');
        // console.log('is short');
      }


      // Parallax footer image.
      if (offsetYcontFooter <= 0) {
        // Range of movement top : 40% - 60% | 0 - -715
        // let down image
        let footerImageParallax = ((offsetYcontFooter/35.15))+60;
        // uplift image
        // let footerImageParallax = ((offsetYcontFooter/35.15)*-1)+40;
        footerImage.style.top = footerImageParallax+'%';
      }
    });
    renderSync = true;
  }
}



let failSafe = () => {
  document.getElementsByTagName("body")[0].classList.remove('ready');
};
window.addEventListener('load', function() {
  // document.getElementsByTagName("body")[0].classList.remove('ready');
  failSafe();
  // fail safe.
  setTimeout(failSafe, 3000);
  document.addEventListener('scroll', scrollEve, {passive: true});
});

// Element
const hamburgerButton = document.getElementById('hamburger');
const burgerClose = document.getElementById('close');
const foundationGrass = document.getElementById('foundation-grass');
const footerImage = document.getElementById('footer-image').getElementsByTagName('img')[0];


// current images.
const hamburgerImageHome = document.getElementById('image-home');
const hamburgerImageCompany = document.getElementById('image-about');
const hamburgerImageWorks = document.getElementById('image-web');
const hamburgerImageSchool = document.getElementById('image-school');
const hamburgerImageContact = document.getElementById('image-contact');

// smart content menu elements.
// const smartMenuHomeButton = document.getElementById('tab-home');
// const smartMenuCompanyButton = document.getElementById('tab-company');
// const smartMenuWorksButton = document.getElementById('tab-works');
// const smartMenuSchoolButton = document.getElementById('tab-school');
// const smartMenuContactButton = document.getElementById('tab-contact');

// // description content
// const descriptionHome = document.getElementById('description-home');
// const descriptionCompany = document.getElementById('description-company');
// const descriptionWorks = document.getElementById('description-works');
// const descriptionSchool = document.getElementById('description-school');
// const descriptionContact = document.getElementById('description-contact');


// let ClearCurrent = () => {
//   smartMenuHomeButton.classList.remove('current');
//   smartMenuCompanyButton.classList.remove('current');
//   smartMenuWorksButton.classList.remove('current');
//   smartMenuSchoolButton.classList.remove('current');
//   smartMenuContactButton.classList.remove('current');

//   descriptionHome.classList.remove('current');
//   descriptionCompany.classList.remove('current');
//   descriptionWorks.classList.remove('current');
//   descriptionSchool.classList.remove('current');
//   descriptionContact.classList.remove('current');
//   // All kill.
//   hamburgerImageHome.classList.remove('current');
//   hamburgerImageCompany.classList.remove('current');
//   hamburgerImageWorks.classList.remove('current');
//   hamburgerImageSchool.classList.remove('current');
//   hamburgerImageContact.classList.remove('current');
// };

// function
// function hoge(word) {
//   console.log(word);
// }
// setTimeout(hoge.bind(this, '余裕やん？'), 250);
//　strict toggle class ctrl.
// let toggleHamburger = () => {
//   if (hamburgerButton.classList.contains('active')) {
//     hamburgerButton.classList.remove('active');
//     burgerClose.classList.remove('active');

//   } else if (!hamburgerButton.classList.contains('active')) {
//     hamburgerButton.classList.add('active');
//     burgerClose.classList.add('active');

//     // foundation grass effect.
//     foundationGrass.classList.add('ready');
//     setTimeout(() => {
//       foundationGrass.classList.add('active');
//     }, 250);
//   }
// }


// main frame/
// on click hamburger_button,
// var currentPath = location.pathname;
// // console.log(currentPath);
// switch (currentPath) {
//   case "/":
//     hamburgerImageCtrl('home');
//     break;

//     case "/company/":
//       hamburgerImageCtrl('company');
//       break;

//     case "/works/":
//       hamburgerImageCtrl('works');
//       break;

//     case "/school/":
//       hamburgerImageCtrl('school');
//       break;

//     case "/contact/":
//       hamburgerImageCtrl('contact');
//       break;

//   default:
//     break;
// }

document.getElementById('hamburger').onclick = () => {
  if (document.getElementById('header').classList.contains('show') !== null) {
    document.getElementById('header').classList.add('show')
  }
}
document.getElementById('close').onclick = () => {
  if (document.getElementById('header').classList.contains('show') !== null) {
    document.getElementById('header').classList.remove('show')
  }
}
// hamburgerButton.onclick = () => {
//   toggleHamburger();
// };
// burgerClose.onclick = function(){}
// burgerClose.onclick = () => {
//   if (burgerClose.classList.contains('active')) {
//     burgerClose.classList.remove('active');
//     hamburgerButton.classList.remove('active');
//     // foundation grass effect.
//     foundationGrass.classList.add('disactive');
//     setTimeout(() => {
//       foundationGrass.classList.remove('active');
//       foundationGrass.classList.remove('ready');
//       foundationGrass.classList.remove('disactive');
//     }, 750);
//     }
//   };

// on click hamburger content tab menus.
/* smartMenuHomeButton / smartMenuCompanyButton / smartMenuWorksButton / smartMenuSchoolButton / smartMenuContactButton */
// smartMenuHomeButton.onclick = () => {
//   ClearCurrent();
//   smartMenuHomeButton.classList.add('current');
//   descriptionHome.classList.add('current');
//   hamburgerImageHome.classList.add('current');
// };
// smartMenuCompanyButton.onclick = () => {
//   ClearCurrent();
//   smartMenuCompanyButton.classList.add('current');
//   descriptionCompany.classList.add('current');
//   hamburgerImageCompany.classList.add('current');
// };
// smartMenuWorksButton.onclick = () => {
//   ClearCurrent();
//   smartMenuWorksButton.classList.add('current');
//   descriptionWorks.classList.add('current');
//   hamburgerImageWorks.classList.add('current');
// };
// smartMenuSchoolButton.onclick = () => {
//   ClearCurrent();
//   smartMenuSchoolButton.classList.add('current');
//   descriptionSchool.classList.add('current');
//   hamburgerImageSchool.classList.add('current');
// };
// smartMenuContactButton.onclick = () => {
//   ClearCurrent();
//   smartMenuContactButton.classList.add('current');
//   descriptionContact.classList.add('current');
//   hamburgerImageContact.classList.add('current');
// };
