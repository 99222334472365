export function hamburgerImageCtrl(currentContent) {
  // smart phone content menu.
  // init.
  const hamburgerImageHome = document.getElementById('image-home');
  const hamburgerImageCompany = document.getElementById('image-about');
  const hamburgerImageWorks = document.getElementById('image-web');
  const hamburgerImageSchool = document.getElementById('image-school');
  const hamburgerImageContact = document.getElementById('image-contact');

  // description image
  const descriptionHome = document.getElementById('description-home');
  const descriptionCompany = document.getElementById('description-company');
  const descriptionWorks = document.getElementById('description-works');
  const descriptionSchool = document.getElementById('description-school');
  const descriptionContact = document.getElementById('description-contact');

  // description tab //たぶんクラスだと認識してくれなくなる
  const tabHome = document.getElementsByClassName('tab home');
  const tabCompany = document.getElementsByClassName('tab company');
  const tabWorks = document.getElementsByClassName('tab works');
  const tabSchool = document.getElementsByClassName('tab school');
  const tabContact = document.getElementsByClassName('tab contact');

  const replaceTextHome = document.getElementById('status-home');
  const replaceTextCompany = document.getElementById('status-company');
  const replaceTextWorks = document.getElementById('status-web');
  const replaceTextSchool = document.getElementById('status-school');
  const replaceTextContact = document.getElementById('status-contact');

  // All kill.
  hamburgerImageHome.classList.remove('current');
  hamburgerImageCompany.classList.remove('current');
  hamburgerImageWorks.classList.remove('current');
  hamburgerImageSchool.classList.remove('current');
  hamburgerImageContact.classList.remove('current');

  let currentHome = () => {
    hamburgerImageHome.classList.add('current');
    descriptionHome.classList.add('current');
    replaceTextHome.textContent = '現在閲覧中です';
  };
  let currentCompany = () => {
    hamburgerImageCompany.classList.add('current');
    descriptionCompany.classList.add('current');
    replaceTextCompany.textContent = '現在閲覧中です';
  }
  let currentWorks = () => {
    hamburgerImageWorks.classList.add('current');
    descriptionWorks.classList.add('current');
    replaceTextWorks.textContent = '現在閲覧中です';
  }
  let currentSchool = () => {
    hamburgerImageSchool.classList.add('current');
    descriptionSchool.classList.add('current');
    replaceTextSchool.textContent = '現在閲覧中です';
  }
  let currentContact = () => {
    hamburgerImageContact.classList.add('current');
    descriptionContact.classList.add('current');
    replaceTextContact.textContent = '現在閲覧中です';
  }

  // brunch.
  switch (currentContent) {
    case 'home':
      currentHome();
      break;

    case 'company':
      currentCompany();
      break;

    case 'works':
      currentWorks();
      break;

    case 'school':
      currentSchool();
      break;

    case 'contact':
      currentContact();
      break;


    default:
      break;
  }


  // on click tab.


}
